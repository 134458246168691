<template>
    <div class="auth-page">
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <div class="auth-page-right d-flex flex-column justify-content-center">
              <div class="auth-page-right-content">
                <div v-if="showLoader" class="lds-dual-ring-registry" id="loading"></div>
                <div v-else class="heading px-2 mb-0 d-flex flex-column justify-content-center align-items-center">
                  <h6>Your order has been confirmed.</h6>
                  <p >Thanks</p>
                </div>
                <div class="account d-md-none">
                  <router-link to="/Login" class="forgot-password">Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "ConfirmedOrder",
  
    data() {
      return {
        showLoader: true,
        
      };
    },
  
    mounted() {
      // Parse the URL to extract the order ID
      const params = new URLSearchParams(window.location.search);
      const orderId = params.get('orderId');
  
      // Send the order ID to the API
      axios.post(process.env.VUE_APP_API_URL + "confirmEmailOrder", { orderId })
        .then(response => {
          if (response.data.statusCode === 200) {
            // If API response status code is 200, stop the loader and show text
            this.showLoader = false;
          } else {
            // Handle other status codes if needed
            console.error('Error confirming email order:', response.data.message);
          }
        })
        .catch(error => {
          console.error('Error confirming email order:', error);
        });
    }
  };
  </script>